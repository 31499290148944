html{
    scroll-behavior: smooth;
}

.quiz{
    scroll-behavior: smooth;
}

*:focus{
    outline: none;
}
#quiz-root, #quiz-root h1, #quiz-root h3, #quiz-root h4, #quiz-root p{
    font-family: "Lato", sans-serif !important;
}
#quiz-root #select-gender, #quiz-root #results {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 0;
}
#quiz-root #select-gender h1, #quiz-root #results h1{
    font-size: 40px;
    font-weight: 900;
    letter-spacing: 1px;
    display: block;
    max-width: 400px;
    line-height: 46px;
}
#quiz-root #select-gender button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 9px;
    background-color: #fff;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1px;
    outline: none!important;
    padding: 15px 25px;
    border: 1px solid transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    width: 270px;
    margin-bottom: 10px;
}
#quiz-root #select-gender .f-btn {
    -webkit-box-shadow: 0 4px 11px -2px rgba(255, 52, 100, 0.5);
    box-shadow: 0 4px 11px -2px rgba(255, 52, 100, 0.5);
    color: #ff3464;
}

#quiz-root #select-gender .m-btn {
    -webkit-box-shadow: 0 4px 11px -2px rgba(86, 70, 143, 0.5);
    box-shadow: 0 4px 11px -2px rgba(86, 70, 143, 0.5);
    color: #56468f;
}
#quiz-root .quiz {
    padding: 0;
    background-color: #fff;
    border-radius: 9px;
    -webkit-box-shadow: 0 4px 11px -2px rgba(86, 70, 143, 0.3);
    box-shadow: 0 4px 11px -2px rgba(86, 70, 143, 0.3);
}
#quiz-root h3, #quiz-root .h3 {
    color: var(--main-color);
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 29px;
}

#quiz-root .bg-custom {
    background-color: var(--main-color);
}
#quiz-root select{
    overflow: unset;
}
#quiz-root form div {
    width:100%;
    border-radius: 9px;
    background-color: #fff;
    box-shadow: 0 4px 11px -7px rgba(0, 0, 0, 0.2);
    opacity: 0.7;
    cursor: pointer;
    position: relative;
    padding: 20px 40px 20px 20px;
    color: #444;
    font-size: 16px;
    letter-spacing: 1px;
    margin-bottom: 10px;
    white-space: normal ;
}
#quiz-root form div:after{
    content: '-';
    border-radius: 0 9px 9px 0;
    background-color: #c3c3c3;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 33px;
    color: #fff;
    font-size: 34px;
    font-weight: 700;
    text-align: center;
    line-height: 35px;
}

#quiz-root form div:hover, #quiz-root form div.selected {
    -webkit-box-shadow: 0 4px 11px -2px rgba(86, 70, 143, 0.3);
    box-shadow: 0 4px 11px -2px rgba(86, 70, 143, 0.3);
    color: var(--main-color);
    opacity: .9;
}
#quiz-root form div.selected{
    opacity: 1;
}
#quiz-root form div.selected:after {
    content: "+";
    background-color: var(--main-color);
}
#quiz-root button[type=submit]{
    border-radius: 22px;
    text-align: center;
    display: block;
    width: 100%;
    background-color: var(--main-color);
    -webkit-box-shadow: 0 4px 11px -2px var(--main-color);
    box-shadow: 0 4px 11px -2px var(--main-color);
    color: #fff;
    font-size: 20px;
    border: 0;
    font-weight: 900;
    outline: none;
    margin-top: 25px;
    letter-spacing: 1px;
    padding: 12px;
    line-height: 23px;
}
#quiz-root button[type=submit]:disabled{
    background-color: #aaa;
    -webkit-box-shadow: 0 4px 11px -2px #777;
    box-shadow: 0 4px 11px -2px #777;
    opacity: .7;
    cursor: not-allowed;
}
#quiz-root input[type=email]{
    border-bottom: 3px solid #eee!important;
}
#quiz-root .related-post {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 660px;
    padding-bottom: 30px;
}
#quiz-root .related-post a {
    width: 200px;
    margin: 10px;
    box-shadow: 1px 1px 15px -8px #333;
    border-radius: 25px;
    color: inherit;
    text-decoration: none;
    overflow: hidden;
    height: auto
}
#quiz-root .related-post img {
    width: 100%
}
#quiz-root .related-post h6 {
    font-weight: 700;
    margin: 10px 20px;
}
#quiz-root .related-post button {
    padding: 3px 16px;
    margin: 0 20px 15px 20px;
    border-radius: 15px;
}

#quiz-root .sticky-ad {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 999999;
    background-color: white;
    box-shadow: 0 0 15px -5px #000;
}
